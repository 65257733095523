import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
export default function MainLogo() {
  const data = useStaticQuery(graphql`
    query getImage {
      file(relativePath: { eq: "bison_from_svg.png" }) {
        childImageSharp {
          fixed(width: 175, height: 175) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return <Img fixed={data.file.childImageSharp.fixed} className="main-img" />;
}

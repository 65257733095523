import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { US, PL } from 'country-flag-icons/react/3x2';

const SwitcherDiv = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;

  button {
    cursor: pointer;
    background: none;
    border: none;
    margin-right: 0.5rem;
  }
`;

export default function Switcher() {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <SwitcherDiv>
      <span>{t('language')}: </span>
      <button onClick={() => changeLanguageHandler('pl')}>
        PL <PL />
      </button>
      <button onClick={() => changeLanguageHandler('en')}>
        EN <US />
      </button>
    </SwitcherDiv>
  );
}

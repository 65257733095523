import React from 'react';
import styled from 'styled-components';
export const SubpageTitle = styled.h2`
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  color: gray;
  font-size: 0.8rem;
  font-weight: bold !important;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaGithub, FaLinkedin, FaFacebook, FaEnvelope } from 'react-icons/fa';
import styled from 'styled-components';
import { SubpageTitle } from './SimpleStyledComponents';

const ContactDiv = styled.div`
  margin-top: 2rem;
  .subtitle {
    color: #eaebed;
  }
  .icon svg {
    fill: #eaebed !important;
  }
`;

const Contact = () => {
  const { t } = useTranslation();
  return (
    <ContactDiv>
      <SubpageTitle className="subtitle">{t('get_in_touch')}</SubpageTitle>
      <nav>
        <ul>
          <li>
            <a href="https://github.com/aboutroots" className="icon simple">
              <FaGithub />
              <span className="label">Github</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/michal-popiel/"
              className="icon simple"
            >
              <FaLinkedin />
              <span className="label">Linkedin</span>
            </a>
          </li>
          <li>
            <a href="mailto:popiel.mw@gmail.com" className="icon simple">
              <FaEnvelope />
              <span className="label">Email</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/aboutroots"
              className="icon simple"
            >
              <FaFacebook />
              <span className="label">Facebook</span>
            </a>
          </li>
        </ul>
      </nav>
    </ContactDiv>
  );
};

export default Contact;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      technologies: 'Technologies',
      language: 'Language',
      about:
        "Hello there!\nI'm a full-stack developer working mostly with Python and VueJS. With a background in architecture and a university degree in computer science, I combine design skills with a clean code throughout the technology stack. If you want to work with me, contact details can be found below.\nCheers!",
      get_in_touch: 'GET IN TOUCH',
      form: {
        name: 'Your name',
        email: 'Your email',
        text: 'Write something',
        submit: 'Submit',
        thankyou: 'Thank you for the message!',
      },
    },
  },
  pl: {
    translation: {
      technologies: 'MOJE TECHNOLOGIE',
      language: 'Język',
      about:
        'Cześć!\nJestem full-stack developerem pracującym głównie z Pythonem i VueJS. Posiadam doświadczenie zarówno w designie architektury aplikacji jak i w tworzeniu UI. Staram się łączyć pasję do projektowania z czystym kodem we wszystkich technologiach, w których pracuję. Jeśli chciałbyś ze mną pracować, kontakt znajdziesz poniżej.\nMiłego dnia!',
      get_in_touch: 'SKONTAKTUJ SIĘ ZE MNĄ',
      form: {
        name: 'Imię',
        email: 'Email',
        text: 'Twoja wiadomość',
        submit: 'Wyślij',
        thankyou: 'Dzięki za wiadomość!',
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
  });
export default i18n;

import React from 'react';
import TechList from './Tech/TechList';
import { SubpageTitle } from './SimpleStyledComponents';
import { FaPython, FaReact, FaVuejs } from 'react-icons/fa';
import GraphqlSVG from '../images/icons/graphql.inline.svg';
import GatsbySVG from '../images/icons/gatsby.inline.svg';
import KubeSVG from '../images/icons/kube.inline.svg';
import BashSVG from '../images/icons/bash.inline.svg';
import FlutterSVG from '../images/icons/flutter.inline.svg';
import DjangoSVG from '../images/icons/django.inline.svg';
import TsSVG from '../images/icons/typescript.inline.svg';
import { useTranslation } from 'react-i18next';

const techList = [
  {
    id: 'vue',
    link: 'https://vuejs.org/',
    title: 'VueJS',
    icon: <FaVuejs />,
  },
  {
    id: 'python',
    link: 'https://www.python.org/',
    title: 'Python',
    icon: <FaPython />,
  },
  {
    id: 'django',
    link: 'https://www.djangoproject.com/',
    title: 'Django',
    icon: <DjangoSVG />,
  },
  {
    id: 'gatsby',
    link: 'https://www.gatsbyjs.org/',
    title: 'GatsbyJS',
    icon: <GatsbySVG />,
  },
  {
    id: 'react',
    link: 'https://reactjs.org/',
    title: 'ReactJS',
    icon: <FaReact />,
  },
  {
    id: 'graphql',
    link: 'https://graphql.org/',
    title: 'GraphQL',
    icon: <GraphqlSVG />,
  },
  {
    id: 'bash',
    link: 'https://tiswww.case.edu/php/chet/bash/bashtop.html',
    title: 'Bash',
    icon: <BashSVG />,
  },
  {
    id: 'kube',
    link: 'https://kubernetes.io/',
    title: 'Kubernetes',
    icon: <KubeSVG />,
  },
  {
    id: 'flutter',
    link: 'https://flutter.dev/',
    title: 'Flutter',
    icon: <FlutterSVG />,
  },
  {
    id: 'typescript',
    link: 'https://www.typescriptlang.org/',
    title: 'TypeScript',
    icon: <TsSVG />,
  },
];

const Technologies = () => {
  const { t } = useTranslation();
  return (
    <div className="fadeIn">
      <SubpageTitle>{t('technologies')}</SubpageTitle>
      <TechList listData={techList} />
    </div>
  );
};

export default Technologies;

/* eslint-disable import/prefer-default-export */
import React from 'react';

// for importing global styles
import './src/assets/sass/main.scss';

import Layout from './src/components/Layout/Layout';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    // eslint-disable-next-line no-unused-expressions
    import(`intersection-observer`);
  }
};

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  // ignore private subpages
  if (element.key.startsWith('/dev')) {
    return element;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Layout {...props}>{element}</Layout>;
};

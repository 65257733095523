import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ContentCard = styled.div`
  padding: 1rem 0;
  width: 100vw;
  margin: auto;
  /* position: absolute; */
  padding: 1rem;

  .text-wrapper {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
    white-space: pre-line;
  }
`;

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <ContentCard>
        <div className="text-wrapper fadeIn">{t('about')}</div>
      </ContentCard>
    </>
  );
};

export default About;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import TechIcon from './TechIcon';
import styled from 'styled-components';

const List = styled.div`
  display: flex;
  width: 600px;
  max-width: 90%;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
`;

const TechList = ({ listData }) => {
  return (
    <List className="techlist">
      {listData.map((el) => (
        <TechIcon icon={el} key={el.id} />
      ))}
    </List>
  );
};

export default TechList;

import { graphql, useStaticQuery } from 'gatsby';

export default function useSiteMetadata() {
  const data = useStaticQuery(graphql`
    query getMetadata {
      site {
        siteMetadata {
          config {
            title
            description
            siteUrl
            shortName
            mainImage
            themeColor
          }
        }
      }
    }
  `);
  return data.site.siteMetadata;
}
